<template>

    <div class="form-group row">
        <label for="circuit_file" class="col-sm-2 col-form-label">CSV File</label>
        <div class="col-sm-10">
            <input type="file"
                   class="form-control"
                   :class="{'is-invalid': errors.hasOwnProperty('circuit_file')}"
                   id="circuit_file"
                   name="circuit_file"
                   required
                   @change="addCsvFile"
                   accept=".csv">
            <small class="form-text text-muted">Upload the CSV from circuit</small>
            <div v-if="errors.hasOwnProperty('circuit_file')" class="invalid-feedback">
                {{ errors.circuit_file[0] }}
            </div>
        </div>
    </div>

    <div class="form-group row" v-for="(driver, index) in drivers">
        <label :for="'drivers[' + index + '][driver]'" class="col-sm-2 col-form-label">Driver</label>
        <div class="col-sm-4">
            <input type="text"
                   class="form-control"
                   :class="{'is-invalid': errors.hasOwnProperty('drivers.' + index + '.driver')}"
                   :id="'drivers[' + index + '][driver]'"
                   :name="'drivers[' + index + '][driver]'"
                   :value="driver">
            <small class="form-text text-muted">The name of the driver for the route</small>
            <div class="invalid-feedback" v-if="errors.hasOwnProperty('drivers.' + index + '.driver')">
                {{ errors.drivers[index].driver }}
            </div>
        </div>

        <label :for="'drivers[' + index + '][route]'" class="col-sm-2 col-form-label">Route Name</label>
        <div class="col-sm-4">
            <input type="text"
                   class="form-control"
                   :class="{'is-invalid': errors.hasOwnProperty('drivers.' + index + '.route')}"
                   :id="'drivers[' + index + '][route]'"
                   :name="'drivers[' + index + '][route]'">
            <small class="form-text text-muted">The name of the route, e.g. LOND-1</small>
            <div class="invalid-feedback" v-if="errors.hasOwnProperty('drivers.' + index + '.route')">
                {{ errors.drivers[index].route }}
            </div>
        </div>

    </div>

</template>

<script>
import {parse} from 'csv-parse/browser/esm';

export default {
    name: 'CircuitUploadForm',
    props: {
        errors: {
            required: false,
            type: Object
        }
    },
    data() {
        return {
            csvContents: []
        };
    },
    methods: {
        addCsvFile(csvFile) {
            let reader = new FileReader();

            reader.addEventListener('load', (e) => {
                parse(e.target.result, {
                    columns: true,
                }, (err, records) => {
                    if(err !== undefined) {
                        alert('An error occured parsing the csv: ' + err);
                    } else {
                        this.csvContents = records;
                    }
                });
            });

            reader.readAsBinaryString(csvFile.target.files[0]);
        }
    },
    computed: {
        drivers() {
            let drivers = [];
            this.csvContents.forEach((contents) => {
                if(drivers.indexOf(contents.driver) === -1) {
                    drivers.push(contents.driver);
                }
            });
            return drivers;
        }
    }
};
</script>

<style scoped>

</style>
